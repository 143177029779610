<template>
  <div>
    <div class="py-5 text-center" v-if="isSalePermission">
      <p class="m-0">Chức năng chưa được phát triển cho vai trò của bạn.</p>
    </div>
    <div v-else>
      <v-container class="py-0" fluid>
        <v-row no-gutters v-if="!isMarketingPermission">
          <v-col sm="12" md="12" class="mb-4 px-2">
            <card-high-chart-statistic :allProductTypes="allProductTypes" />
          </v-col>
          <v-col sm="12" md="12" class="mb-4 px-2">
            <!--        <card-high-chart-analytics />-->
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="px-2 py-0" cols="12">
            <div class="d-flex align-center mb-3">
              <v-btn
                v-for="item in filters"
                :key="item.id"
                class="rounded-pill mr-3"
                :color="item.id === filterSelected.id ? 'primary' : ''"
                depressed
                @click="selectFilter(item)"
              >
                {{ item.text }}
              </v-btn>
            </div>
          </v-col>
          <v-col
            class="px-2 mb-4"
            sm="12"
            md="12"
            lg="6"
            v-if="filterSelected.value.includes(1)"
          >
            <card-products-on-stock :allProductTypes="allProductTypes" />
          </v-col>
          <v-col
            class="px-2 mb-4 d-none"
            sm="12"
            md="12"
            lg="6"
            v-if="filterSelected.value.includes(2)"
          >
            <card-just-finished-selling />
          </v-col>
          <v-col
            class="px-2 mb-4"
            sm="12"
            md="12"
            lg="6"
            v-if="filterSelected.value.includes(3)"
          >
            <card-many-inventory :allProductTypes="allProductTypes" />
          </v-col>
          <v-col
            class="px-2 mb-4 d-none"
            sm="12"
            md="12"
            lg="6"
            v-if="filterSelected.value.includes(4)"
          >
            <card-emerging-warehouse />
          </v-col>
          <v-col
            class="px-2 mb-4"
            sm="12"
            md="12"
            lg="6"
            v-if="filterSelected.value.includes(5)"
          >
            <card-just-ordered :allProductTypes="allProductTypes" />
          </v-col>
          <v-col
            class="px-2 mb-4"
            sm="12"
            md="12"
            lg="6"
            v-if="filterSelected.value.includes(6)"
          >
            <card-ancient :allProductTypes="allProductTypes" />
          </v-col>
          <v-col
            class="px-2 mb-4 d-none"
            sm="12"
            md="12"
            lg="6"
            v-if="filterSelected.value.includes(7)"
          >
            <card-waiting-guests />
          </v-col>
          <v-col
            class="px-2 mb-4 d-none"
            sm="12"
            md="12"
            lg="6"
            v-if="filterSelected.value.includes(8)"
          >
            <card-promotion />
          </v-col>
          <v-col
            class="px-2 mb-4 d-none"
            sm="12"
            md="12"
            lg="6"
            v-if="filterSelected.value.includes(9)"
          >
            <card-just-orders-by-customer />
          </v-col>
          <v-col
            class="px-2 mb-4 d-none"
            sm="12"
            md="12"
            lg="6"
            v-if="filterSelected.value.includes(10)"
          >
            <card-top-selling />
          </v-col>
          <v-col
            class="px-2 mb-4 d-none"
            sm="12"
            md="12"
            lg="6"
            v-if="filterSelected.value.includes(11)"
          >
            <card-products-return />
          </v-col>
          <v-col
            class="px-2 mb-4 d-none"
            sm="12"
            md="12"
            lg="6"
            v-if="filterSelected.value.includes(12)"
          >
            <card-new-product />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
// import CardHighChartAnalytics from "@/modules/Dashboard/components/CardHighChartAnalytics";
import CardHighChartStatistic from "@/modules/Dashboard/components/CardHighChartStatistic";
import CardProductsOnStock from "@/modules/Dashboard/components/CardProductsOnStock";
import CardJustFinishedSelling from "@/modules/Dashboard/components/CardJustFinishedSelling";
import CardManyInventory from "@/modules/Dashboard/components/CardManyInventory";
import CardEmergingWarehouse from "@/modules/Dashboard/components/CardEmergingWarehouse";
import CardAncient from "@/modules/Dashboard/components/CardAncient";
import CardJustOrdered from "@/modules/Dashboard/components/CardJustOrdered";
import CardWaitingGuests from "@/modules/Dashboard/components/CardWaitingGuests";
import CardPromotion from "@/modules/Dashboard/components/CardPromotion";
import CardJustOrdersByCustomer from "@/modules/Dashboard/components/CardJustOrdersByCustomer";
import CardTopSelling from "@/modules/Dashboard/components/CardTopSelling";
import CardNewProduct from "@/modules/Dashboard/components/CardNewProduct";
import CardProductsReturn from "@/modules/Dashboard/components/CardProductsReturn";
import { mapGetters } from "vuex";

export default {
  components: {
    CardHighChartStatistic,
    // CardHighChartAnalytics,
    CardJustOrdered,
    CardAncient,
    CardWaitingGuests,
    CardProductsOnStock,
    CardJustFinishedSelling,
    CardManyInventory,
    CardEmergingWarehouse,
    CardPromotion,
    CardJustOrdersByCustomer,
    CardTopSelling,
    CardNewProduct,
    CardProductsReturn
  },
  data() {
    return {
      filterSelected: {
        value: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        id: 0
      },
      filters: [
        {
          id: 0,
          text: "Tất cả",
          value: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
        },
        // {
        //   id: 1,
        //   text: "Hàng đã về",
        //   value: [0, 1, 5]
        // },
        {
          id: 2,
          text: "Tồn kho",
          value: [0, 1, 3, 5, 6]
        }
        // {
        //   id: 3,
        //   text: "Bán hàng/Trả hàng",
        //   value: [0, 2, 11]
        // },
        // {
        //   id: 4,
        //   text: "Khuyến mãi",
        //   value: [0, 8]
        // },
        // {
        //   id: 5,
        //   text: "Khách đặt hàng",
        //   value: [0, 7]
        // },
        // {
        //   id: 6,
        //   text: "Cập nhập sản phẩm",
        //   value: [0, 12]
        // }
      ]
    };
  },
  computed: {
    ...mapGetters({
      allProductTypes: "PRODUCT_TYPE/allProductTypes"
    }),
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },
  created() {
    this.$store.dispatch("PRODUCT_TYPE/getAllProductTypes");
    this.$store.dispatch("DASHBOARD_FILTERS/getJustOrders", {
      filters: {
        quantity: 50,
        nguon_hang: [],
        category_id: []
      }
    });
    this.$store.dispatch("DASHBOARD_FILTERS/getArriving", {
      filters: {
        quantity: 50,
        nguon_hang: [],
        category_id: []
      }
    });
    this.$store.dispatch("DASHBOARD_FILTERS/getManyInventory", {
      filters: {
        quantity: 50,
        nguon_hang: [],
        category_id: []
      }
    });
    this.$store.dispatch("DASHBOARD_FILTERS/getLivedLong", {
      filters: {
        quantity: 50,
        nguon_hang: [],
        category_id: []
      }
    });
  },
  methods: {
    selectFilter(item) {
      this.filterSelected = {
        value: item.value,
        id: item.id
      };
    }
  }
};
</script>
