<template>
  <div>
    <v-card flat class="pa-4">
      <div class="flex-row">
        <div class="d-flex align-center mb-4">
          <p class="font-weight-bold text-h5 mb-0">
            Vừa bị khách "hồi" lại
          </p>
        </div>
      </div>
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(item, i) in 3" :key="i">
          <v-expansion-panel-header>
            <v-row>
              <v-col sm="1"
                ><span class="font-weight-bold text-h6">{{ i }}</span></v-col
              >
              <v-col><span class="font-weight-bold">Time</span></v-col>
              <v-col
                ><span class="font-weight-bold"
                  >Tên sản phẩm SKU, MPN</span
                ></v-col
              >
              <v-col><span class="font-weight-bold">Chứng từ</span></v-col>
              <v-col><span class="font-weight-bold">Giá trị hàng</span></v-col>
              <v-col><span class="font-weight-bold">Phí trả hàng</span></v-col>
              <v-col
                ><span class="font-weight-bold">Phiếu trả chờ nhập</span></v-col
              >
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col sm="1"
                ><span class="font-weight-bold text-h6"></span
              ></v-col>
              <v-col><span>00:00</span></v-col>
              <v-col><span>Tên sản phẩm SKU, MPN</span></v-col>
              <v-col><span>3</span></v-col>
              <v-col><span>5 ngày</span></v-col>
              <v-col><span>3</span></v-col>
              <v-col><span>5 ngày</span></v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none;
}
.v-expansion-panel-header {
  background: rgb(251 251 251);
}
</style>
