<template>
  <div class="products-on-stock">
    <v-card flat class="pa-4">
      <div class="flex-row">
        <div class="d-flex align-center mb-4">
          <p class="font-weight-bold text-h5 mb-0">
            Sản phẩm về kho - Lo cho khách đặt
          </p>
          <a
            href="https://cms.thinkpro.vn/#/goods/product-options?store_status=2&ttkd=1&limit=50&page=1"
            class="ml-auto view-details"
            target="_blank"
            >Xem danh sách đặt</a
          >
        </div>
      </div>
      <div>
        <v-data-table
          :headers="dessertHeaders"
          :items="itemsIsArriving"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          show-expand
          item-key="stt"
          class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
          no-data-text="Không có dữ liệu"
          no-results-text="Không tìm thấy kết quả phù hợp"
          loading-text="Đang tải dữ liệu"
          hide-default-footer
          calculate-widths
          style="overflow: auto;"
          @page-count="pageCount = $event"
        >
          <template
            v-slot:[`item.product_name`]="{ item }"
            style="width: 70px;"
          >
            <span
              >{{ item.product_name }} - {{ item.SKU }} -
              {{ item.short_des }}</span
            >
          </template>
          <template v-slot:[`item.earliest_est_date`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div class="copied-label" v-bind="attrs" v-on="on">
                  {{ item.earliest_est_date | formatTime }}
                </div>
              </template>
              <span>{{ item.earliest_est_date | formatGetNumberDay }}</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <!--                        <table>-->
            <td :colspan="headers.length" style="padding: 0 !important;">
              <!--                        </table>-->
              <v-data-table
                :headers="defaultHeaderChild"
                :items="item.pos"
                item-key="stt"
                class="tp-table-scroll tp-table__row-pointer datatable"
                calculate-widths
                no-data-text="Không có dữ liệu"
                no-results-text="Không tìm thấy kết quả phù hợp"
                loading-text="Đang tải dữ liệu"
                hide-default-footer
                style="max-height: 300px; overflow: auto;"
              >
                <template v-slot:[`item.minEstDate`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="copied-label" v-bind="attrs" v-on="on">
                        {{ item.minEstDate | formatTime }}
                      </div>
                    </template>
                    <span>{{ item.minEstDate | formatGetNumberDay }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
        <div class="d-flex align-center justify-end flex-wrap mt-3">
          <v-autocomplete
            class="tp-filter-autocomplete mx-2"
            clearable
            v-model="selectFilter.nguon_hang"
            :items="nguon_hang"
            dense
            deletable-chips
            multiple
            hide-details
            hide-selected
            item-text="name"
            item-value="id"
            no-data-text="Không có dữ liệu"
            outlined
            single-line
            small-chips
            return-object
            label="Outlined"
            placeholder="Chọn nguồn hàng"
            @change="getArriving()"
            style="max-width: 200px"
          ></v-autocomplete>
          <v-autocomplete
            class="tp-filter-autocomplete mx-2"
            clearable
            v-model="selectFilter.category_id"
            :items="allProductTypes"
            dense
            deletable-chips
            multiple
            hide-details
            hide-selected
            item-text="name"
            item-value="id"
            no-data-text="Không có dữ liệu"
            outlined
            single-line
            small-chips
            return-object
            label="Outlined"
            placeholder="Chọn phụ kiện"
            @change="getArriving()"
            style="max-width: 200px"
          ></v-autocomplete>
          <v-pagination
            v-if="itemsIsArriving.length > 0"
            color="primary"
            v-model="page"
            :length="pageCount"
            :total-visible="5"
          ></v-pagination>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    allProductTypes: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dessertHeaders: [
        {
          text: "STT",
          align: "center",
          sortable: false,
          value: "stt",
          width: "5%"
        },
        { text: "Tên sản phẩm", value: "product_name", width: "40%" },
        {
          text: "SL đang về",
          value: "incoming_qty",
          width: "15%",
          align: "center"
        },
        {
          text: "SL sẵn bán",
          value: "ready_to_sell_qty",
          width: "15%",
          align: "center"
        },
        {
          text: "Thời gian dự tính",
          align: "center",
          sortable: false,
          value: "earliest_est_date",
          width: "20%"
        },
        { text: "", value: "data-table-expand", width: "5%" }
      ],
      defaultHeaderChild: [
        {
          text: "",
          align: "center",
          sortable: false,
          value: "stt",
          width: "5%"
        },
        { text: "Mã PO", sortable: false, value: "code", width: "40%" },
        {
          text: "SL đang về",
          align: "center",
          value: "incoming_qty",
          width: "15%"
        },
        {
          text: "",
          value: "ready_to_sell_qty",
          align: "center",
          width: "15%"
        },
        {
          text: "Thời gian tối thiểu",
          align: "center",
          sortable: false,
          value: "minEstDate",
          width: "20%"
        },
        { text: "", value: "hidden", width: "5%" }
      ],
      selectFilter: {
        nguon_hang: null,
        category_id: null
      },
      nguon_hang: [
        {
          id: 5,
          name: "Chính hãng"
        },
        {
          id: 6,
          name: "Nhập khẩu"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      isArriving: "DASHBOARD_FILTERS/isArriving"
    }),
    itemsIsArriving() {
      return this.isArriving.map((item, index) => {
        return {
          ...item,
          stt: index + 1
        };
      });
    }
  },
  filters: {
    formatTime(d) {
      const dateTime = new Date(d),
        date = String(dateTime.getDate()).padStart(2, "0"),
        month = String(dateTime.getMonth() + 1).padStart(2, "0");

      return `${date}/${month}`;
    },
    formatGetNumberDay(value) {
      let result;
      const nextTime = new Date(value);
      const currentTime = new Date();
      const numberTimestamp = nextTime.getTime() - currentTime.getTime();
      if (numberTimestamp < 0) result = "Đã hết hạn";
      else result = Math.ceil(numberTimestamp / (1000 * 3600 * 24)) + " ngày";
      return result;
    }
  },
  methods: {
    getArriving() {
      let category_id, nguon_hang;
      if (
        this.selectFilter.category_id &&
        this.selectFilter.category_id.length > 0
      ) {
        category_id = this.selectFilter.category_id.map(item => {
          return item.id;
        });
      }
      if (
        this.selectFilter.nguon_hang &&
        this.selectFilter.nguon_hang.length > 0
      ) {
        nguon_hang = this.selectFilter.nguon_hang.map(item => {
          return item.id;
        });
      }
      this.$store.dispatch("DASHBOARD_FILTERS/getArriving", {
        filters: {
          nguon_hang,
          category_id,
          quantity: 50
        }
      });
    }
  }
};
</script>

<style>
.products-on-stock table tbody {
  background: #f9f9f9;
}
/*.products-on-stock table .v-data-table-header {*/
/*  display: none;*/
/*}*/

.products-on-stock .v-data-table tbody tr.v-data-table__expanded__content {
  background: #ffffff;
  box-shadow: none;
}
.products-on-stock .v-data-table__wrapper {
  overflow-x: hidden;
}
</style>

<style scoped lang="scss">
.view-details {
  font-size: 12px;
}
</style>
