<template>
  <div>
    <v-card class="px-4" flat>
      <div class="filters-tabs d-flex align-center mb-7">
        <div
          class="tab"
          @click="getPeriodsChartStatistic(item.id, item.title, item)"
          v-for="item in filterChartStatistic"
          :key="item.id"
          :class="
            typeChartSelected && typeChartSelected.id === item.id
              ? 'active'
              : ''
          "
        >
          <p class="name my-0">{{ item.title }}</p>
          <p class="total my-0">
            <span v-if="item.value && item.value > 0 && item.value > 9999">
              {{ item.value | intToString }}</span
            >
            <span v-else-if="item.value && item.value > 0 && item.value < 9999">
              <span v-if="item.id === 'quantity'">{{ item.value }} SP</span>
              <span v-else>{{ item.value | formatCurrency }}</span>
            </span>
            <span v-else>0</span>
          </p>
        </div>
      </div>
      <HighCharts
        ref="lineChartsStatistic"
        :options="chartStatisticOptions"
        class="chart-statistic"
        :class="showLoading ? 'show-loading' : ''"
      ></HighCharts>
      <div class="d-flex align-center flex-wrap" sm="12">
        <div
          v-if="selectFilter.category_id && selectFilter.category_id.length > 0"
          class="d-flex align-center mb-4"
        >
          <v-checkbox
            :disabled="statusChartStatistic === 0"
            v-model="selectFilter.khuyenMai"
            :label="`Khuyễn mãi`"
            class="mx-2"
          ></v-checkbox>
          <v-checkbox
            :disabled="statusChartStatistic === 0"
            v-model="selectFilter.xuatBu"
            :label="`Xuất bù`"
            class="mx-2"
            @change="
              getPeriodsChartStatistic(
                typeChartSelected.id,
                typeChartSelected.title
              )
            "
          ></v-checkbox>
        </div>
        <div
          class="d-flex align-center mb-4"
          v-if="selectFilter.date && selectFilter.date === 3"
        >
          <div class="mr-3">
            <div style="font-size: 12px;"><b>Từ ngày:</b></div>
            <tp-input-date
              :max="to_date"
              :value="from_date"
              custom-class="text-body-2 mt-0"
              custom-style="width: 68px"
              dense
              hide-details
              @change="
                changeDateCustomDate(
                  1,
                  $event,
                  typeChartSelected.id,
                  typeChartSelected.title
                )
              "
            ></tp-input-date>
          </div>
          <div class="mr-3">
            <div style="font-size: 12px;"><b>Đến ngày:</b></div>
            <tp-input-date
              :value="to_date"
              :min="from_date"
              custom-class="text-body-2 mt-0"
              custom-style="width: 68px"
              dense
              hide-details
              @change="
                changeDateCustomDate(
                  2,
                  $event,
                  typeChartSelected.id,
                  typeChartSelected.title
                )
              "
            ></tp-input-date>
          </div>
        </div>
        <v-autocomplete
          class="tp-filter-autocomplete text-body-1 mr-3 mb-4"
          dense
          :disabled="statusChartStatistic === 0"
          v-model="selectFilter.date"
          :items="date"
          placeholder="Chọn thời gian"
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          single-line
          label="Outlined"
          @change="
            getPeriodsChartStatistic(
              typeChartSelected.id,
              typeChartSelected.title
            )
          "
          style="max-width: 170px"
        >
        </v-autocomplete>
        <v-autocomplete
          class="tp-filter-autocomplete text-body-1 mr-3 mb-4"
          dense
          clearable
          :disabled="statusChartStatistic === 0 || selectFilter.xuatBu"
          v-model="selectFilter.price"
          :items="prices"
          item-text="name"
          item-value="id"
          hide-details
          hide-selected
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn khoảng giá"
          single-line
          @change="
            getPeriodsChartStatistic(
              typeChartSelected.id,
              typeChartSelected.title
            )
          "
          style="max-width: 170px"
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="selectFilter.branch_id"
          class="tp-filter-autocomplete mr-3 mb-4"
          clearable
          :items="branches"
          dense
          deletable-chips
          multiple
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          single-line
          small-chips
          return-object
          label="Outlined"
          placeholder="Chọn chi nhánh"
          @change="
            getPeriodsChartStatistic(
              typeChartSelected.id,
              typeChartSelected.title
            )
          "
          style="max-width: 200px"
          :disabled="statusChartStatistic === 0"
        ></v-autocomplete>
        <v-autocomplete
          v-model="selectFilter.staff_id"
          class="tp-filter-autocomplete mr-3 mb-4"
          clearable
          :items="employees"
          dense
          deletable-chips
          multiple
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          single-line
          small-chips
          return-object
          label="Outlined"
          placeholder="Chọn nhân viên"
          @change="
            getPeriodsChartStatistic(
              typeChartSelected.id,
              typeChartSelected.title
            )
          "
          style="max-width: 200px"
          :disabled="statusChartStatistic === 0"
        ></v-autocomplete>
        <v-autocomplete
          class="tp-filter-autocomplete mr-3 mb-4"
          clearable
          :disabled="statusChartStatistic === 0"
          v-model="selectFilter.nguon_hang"
          :items="nguon_hang"
          dense
          deletable-chips
          multiple
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          single-line
          small-chips
          return-object
          label="Outlined"
          placeholder="Chọn nguồn hàng"
          @change="
            getPeriodsChartStatistic(
              typeChartSelected.id,
              typeChartSelected.title
            )
          "
          style="max-width: 200px"
        ></v-autocomplete>
        <v-autocomplete
          class="tp-filter-autocomplete mr-3 mb-4"
          clearable
          :disabled="statusChartStatistic === 0"
          v-model="selectFilter.category_id"
          :items="allProductTypes"
          dense
          deletable-chips
          multiple
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          single-line
          small-chips
          return-object
          label="Outlined"
          placeholder="Chọn phụ kiện"
          @change="
            getPeriodsChartStatistic(
              typeChartSelected.id,
              typeChartSelected.title
            )
          "
          style="max-width: 200px"
        ></v-autocomplete>
      </div>
    </v-card>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import { mapGetters } from "vuex";
export default {
  props: {
    allProductTypes: {
      type: Array,
      require: true
    }
  },
  components: {
    HighCharts: Chart
  },
  data() {
    return {
      from_date: null,
      to_date: null,
      prices: [
        {
          id: 1,
          name: "Dưới 10 triệu"
        },
        {
          id: 2,
          name: "Từ 10 đến 15 triệu"
        },
        {
          id: 3,
          name: "Từ 15 đến 20 triệu"
        },
        {
          id: 4,
          name: "Từ 20 đến 25 triệu"
        },
        {
          id: 5,
          name: "Từ 25 đến 30 triệu"
        },
        {
          id: 6,
          name: "Trên 30 triệu"
        }
      ],
      nguon_hang: [
        {
          id: 5,
          name: "Chính hãng"
        },
        {
          id: 6,
          name: "Nhập khẩu"
        }
      ],
      date: [
        {
          id: 1,
          name: "7 ngày trước"
        },
        {
          id: 2,
          name: "1 tháng trước"
        },
        {
          id: 3,
          name: "Tùy chọn thời gian"
        }
      ],
      selectFilter: {
        date: 1,
        khuyenMai: false,
        xuatBu: false,
        price: null,
        branch_id: null,
        staff_id: null,
        category_id: null,
        nguon_hang: null
      },
      typeChartSelected: {
        id: "revenue",
        title: "Doanh Thu",
        value: null
      },
      showLoading: false
    };
  },
  computed: {
    ...mapGetters({
      chartStatistic: "DASHBOARD/chartStatistic",
      branches: "BRANCH/allBranches",
      employees: "EMPLOYEE/allEmployees",
      statusChartStatistic: "DASHBOARD/statusChartStatistic",
      filterChartStatistic: "DASHBOARD/filterChartStatistic",
      typeChart: "DASHBOARD/typeChart",
      chartStatisticOptions: "DASHBOARD/chartStatisticOptions"
    })
  },
  filters: {
    intToString(value) {
      let suffixes = [" đ", " K", " Tr", " Tỷ", " T"];
      let suffixNum = Math.floor(("" + value).length / 3);
      // 123.456.789.123 123456789123
      // if (suffixNum)
      let result;
      let shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(3)
      );
      if (shortValue % 1 !== 0 && shortValue > 1) {
        shortValue = shortValue.toFixed(1);
        result = shortValue + suffixes[suffixNum];
      } else if (shortValue % 1 !== 0 && shortValue < 1) {
        // shortValue = shortValue.toFixed(1);
        result = shortValue * 1000 + suffixes[suffixNum - 1];
      } else {
        shortValue = shortValue.toFixed(1);
        result = shortValue + suffixes[suffixNum];
      }
      return result;
    }
  },
  async created() {
    if (this.branches && this.branches.length === 0)
      await this.$store.dispatch("BRANCH/getAllBranches");
    if (this.employees && this.employees.length === 0) {
      await this.$store.dispatch("EMPLOYEE/getAllEmployees");
    }
    await this.getPeriodsChartStatistic("revenue", "Doanh thu");
  },
  methods: {
    clearCustomDate() {
      this.from_date = null;
      this.to_date = null;
    },
    changeDateCustomDate(key, e, type, name) {
      if (key === 1) this.from_date = e;
      if (key === 2) this.to_date = e;
      this.getPeriodsChartStatistic(type, name);
    },
    handleConvertPrice() {
      if (this.selectFilter.price && this.selectFilter.price) {
        if (this.selectFilter.price === 1)
          return {
            from: 0,
            to: 10000000
          };
        if (this.selectFilter.price === 2)
          return {
            from: 10000000,
            to: 15000000
          };
        if (this.selectFilter.price === 3)
          return {
            from: 15000000,
            to: 20000000
          };
        if (this.selectFilter.price === 4)
          return {
            from: 20000000,
            to: 25000000
          };
        if (this.selectFilter.price === 5)
          return {
            from: 25000000,
            to: 30000000
          };
        if (this.selectFilter.price === 6)
          return {
            from: 30000000,
            to: null
          };
      } else
        return {
          from: null,
          to: null
        };
    },
    compareTwosDate(date1, date2) {
      if (date1 > date2) return false;
      else if (date1 < date2) return true;
      else return true;
    },
    async getPeriodsChartStatistic(type, name, typeChartSelected) {
      this.showLoading = true;
      if (typeChartSelected) this.typeChartSelected = typeChartSelected;
      let price;
      if (
        this.selectFilter.xuatBu &&
        this.selectFilter.category_id &&
        this.selectFilter.category_id.length > 0
      ) {
        price = {
          from: 0,
          to: 20000
        };
      } else {
        this.selectFilter.xuatBu = false;
        price = this.handleConvertPrice();
      }
      let staff_id,
        branch_id,
        category_id,
        nguon_hang,
        from_date,
        to_date,
        previous_date,
        canRequest = true;
      if (this.selectFilter.date && this.selectFilter.date === 1) {
        // get date
        const dateTo = new Date();
        let dateFrom = new Date();
        dateFrom.setDate(dateTo.getDate() - 7);
        from_date = dateFrom.toLocaleDateString("en-GB").replaceAll("/", "-");
        to_date = dateTo.toLocaleDateString("en-GB").replaceAll("/", "-");
        let previous = new Date();
        previous.setDate(dateTo.getDate() - 14);
        previous_date = previous
          .toLocaleDateString("en-GB")
          .replaceAll("/", "-");
        // Clear Custom Date
        this.clearCustomDate();
        canRequest = true;
      } else if (this.selectFilter.date && this.selectFilter.date === 2) {
        // get date
        const dateTo = new Date();
        let dateFrom = new Date();
        dateFrom.setDate(dateTo.getDate() - 30);
        from_date = dateFrom.toLocaleDateString("en-GB").replaceAll("/", "-");
        to_date = dateTo.toLocaleDateString("en-GB").replaceAll("/", "-");
        let previous = new Date();
        previous.setDate(dateTo.getDate() - 60);
        previous_date = previous
          .toLocaleDateString("en-GB")
          .replaceAll("/", "-");
        // Clear Custom Date
        this.clearCustomDate();
        canRequest = true;
      } else if (this.selectFilter.date && this.selectFilter.date === 3) {
        if (
          this.to_date &&
          this.to_date.length > 0 &&
          this.from_date &&
          this.from_date.length > 0
        ) {
          const dateTo = new Date(this.to_date);
          const dateFrom = new Date(this.from_date);
          from_date = dateFrom.toLocaleDateString("en-GB").replaceAll("/", "-");
          to_date = dateTo.toLocaleDateString("en-GB").replaceAll("/", "-");
          let previous = new Date(this.from_date);
          previous.setDate(
            dateFrom.getDate() - (dateTo - dateFrom) / (24 * 60 * 60 * 1000)
          );
          previous_date = previous
            .toLocaleDateString("en-GB")
            .replaceAll("/", "-");
          canRequest = true;
        } else {
          canRequest = false;
        }
      }
      if (
        this.selectFilter.branch_id &&
        this.selectFilter.branch_id.length > 0
      ) {
        branch_id = this.selectFilter.branch_id.map(item => {
          return item.id;
        });
      }
      if (this.selectFilter.staff_id && this.selectFilter.staff_id.length > 0) {
        staff_id = this.selectFilter.staff_id.map(item => {
          return item.id;
        });
      }
      if (
        this.selectFilter.category_id &&
        this.selectFilter.category_id.length > 0
      ) {
        category_id = this.selectFilter.category_id.map(item => {
          return item.id;
        });
      }
      if (
        this.selectFilter.nguon_hang &&
        this.selectFilter.nguon_hang.length > 0
      ) {
        nguon_hang = this.selectFilter.nguon_hang.map(item => {
          return item.id;
        });
      }
      if (canRequest) {
        await this.$store.dispatch("DASHBOARD/getPeriodsChartStatistic", {
          type: type,
          name: name,
          currentTime: {
            filters: {
              from_date: from_date,
              to_date: to_date,
              from_price: price.from,
              to_price: price.to,
              staff_id: staff_id ? staff_id : [],
              branch_id: branch_id ? branch_id : [],
              category_id: category_id ? category_id : [],
              nguon_hang: nguon_hang ? nguon_hang : [],
              needs: ["periods"]
            }
          },
          previousCycle: {
            filters: {
              from_date: previous_date,
              to_date: from_date,
              from_price: price.from,
              to_price: price.to,
              staff_id: staff_id ? staff_id : [],
              branch_id: branch_id ? branch_id : [],
              category_id: category_id ? category_id : [],
              nguon_hang: nguon_hang ? nguon_hang : [],
              needs: ["periods"]
            }
          },
          total: {
            filters: {
              from_date: from_date,
              to_date: to_date,
              needs: ["total"],
              from_price: price.from,
              to_price: price.to,
              staff_id: staff_id ? staff_id : [],
              branch_id: branch_id ? branch_id : [],
              category_id: category_id ? category_id : [],
              nguon_hang: nguon_hang ? nguon_hang : []
            }
          }
        });
      }
      this.showLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .highcharts-credits {
  display: none;
}
.filters-tabs {
  .tab {
    margin-right: 3rem;
    cursor: pointer;
    border-top: 3px solid transparent;
    .name {
      font-size: 14px;
      padding-top: 5px;
    }
    .total {
      font-size: 26px;
    }
    &.active {
      border-top: 3px solid #27bece;
      .name,
      .total {
        font-weight: 600;
      }
    }
  }
}
.chart-statistic {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgb(255 255 255 / 50%);
    z-index: 1;
    display: none;
  }
  &:after {
    content: "Đang tải dữ liệu...";
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    color: #333333;
    z-index: 2;
    display: none;
  }
  &.show-loading {
    &:before,
    &:after {
      display: block;
    }
  }
}
</style>
