<template>
  <div class="many-inventory">
    <v-card flat class="pa-4">
      <div class="flex-row">
        <div class="d-flex align-center mb-4">
          <p class="font-weight-bold text-h5 mb-0">
            Tồn kho nhiều - Nhà đông anh em
          </p>
          <a
            href="https://cms.thinkpro.vn/#/goods/product-options?store_status=1&ttkd=1&sort_by=ready_to_sell_qty&sort_type=2&limit=50&page=1"
            class="ml-auto view-details"
            target="_blank"
            >Xem danh sách sort tồn nhiều</a
          >
        </div>
      </div>
      <div>
        <v-data-table
          :headers="dessertHeaders"
          :items="itemsManyInventory"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          item-key="stt"
          class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
          calculate-widths
          no-data-text="Không có dữ liệu"
          no-results-text="Không tìm thấy kết quả phù hợp"
          loading-text="Đang tải dữ liệu"
          hide-default-footer
          style="overflow: auto;"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.product_name`]="{ item }">
            <span
              >{{ item.product_name }} - {{ item.SKU }} -
              {{ item.short_des }}</span
            >
          </template>
          <template v-slot:[`item.revenue_last_7_days`]="{ item }">
            <span>{{ item.revenue_last_7_days | formatCurrency }}</span>
          </template>
        </v-data-table>
        <div class="d-flex align-center justify-end flex-wrap mt-3">
          <v-autocomplete
            class="tp-filter-autocomplete mx-2"
            clearable
            v-model="selectFilter.nguon_hang"
            :items="nguon_hang"
            dense
            deletable-chips
            multiple
            hide-details
            hide-selected
            item-text="name"
            item-value="stt"
            no-data-text="Không có dữ liệu"
            outlined
            single-line
            small-chips
            return-object
            label="Outlined"
            placeholder="Chọn nguồn hàng"
            @change="getManyInventory()"
            style="max-width: 200px"
          ></v-autocomplete>
          <v-autocomplete
            class="tp-filter-autocomplete mx-2"
            clearable
            v-model="selectFilter.category_id"
            :items="allProductTypes"
            dense
            deletable-chips
            multiple
            hide-details
            hide-selected
            item-text="name"
            item-value="id"
            no-data-text="Không có dữ liệu"
            outlined
            single-line
            small-chips
            return-object
            label="Outlined"
            placeholder="Chọn phụ kiện"
            @change="getManyInventory()"
            style="max-width: 200px"
          ></v-autocomplete>
          <v-pagination
            color="primary"
            v-model="page"
            :length="pageCount"
            :total-visible="5"
            v-if="itemsManyInventory.length > 0"
          ></v-pagination>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    allProductTypes: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dessertHeaders: [
        {
          text: "STT",
          align: "center",
          sortable: false,
          value: "stt"
        },
        { text: "Tên sản phẩm", value: "product_name" },
        { text: "SL đang về", value: "incoming_qty" },
        { text: "SL sẵn bán", value: "ready_to_sell_qty" },
        { text: "Doanh số 7 ngày gần nhất", value: "revenue_last_7_days" }
      ],
      selectFilter: {
        nguon_hang: null,
        category_id: null
      },
      nguon_hang: [
        {
          id: 5,
          name: "Chính hãng"
        },
        {
          id: 6,
          name: "Nhập khẩu"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      manyInventory: "DASHBOARD_FILTERS/manyInventory"
    }),
    itemsManyInventory() {
      return this.manyInventory.map((item, index) => {
        return {
          ...item,
          stt: index + 1
        };
      });
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    getManyInventory() {
      let category_id, nguon_hang;
      if (
        this.selectFilter.category_id &&
        this.selectFilter.category_id.length > 0
      ) {
        category_id = this.selectFilter.category_id.map(item => {
          return item.id;
        });
      }
      if (
        this.selectFilter.nguon_hang &&
        this.selectFilter.nguon_hang.length > 0
      ) {
        nguon_hang = this.selectFilter.nguon_hang.map(item => {
          return item.id;
        });
      }
      this.$store.dispatch("DASHBOARD_FILTERS/getManyInventory", {
        filters: {
          nguon_hang,
          category_id,
          quantity: 50
        }
      });
    }
  }
};
</script>

<style>
.many-inventory table tbody {
  background: #f9f9f9;
}

.many-inventory .v-data-table tbody tr.v-data-table__expanded__content {
  background: #ffffff;
  box-shadow: none;
}
.many-inventory .v-data-table__wrapper {
  overflow-x: hidden;
}
</style>

<style scoped lang="scss">
.view-details {
  font-size: 12px;
}
</style>
