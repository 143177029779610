var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"products-on-stock"},[_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},[_c('div',{staticClass:"flex-row"},[_c('div',{staticClass:"d-flex align-center mb-4"},[_c('p',{staticClass:"font-weight-bold text-h5 mb-0"},[_vm._v(" Sản phẩm về kho - Lo cho khách đặt ")]),_c('a',{staticClass:"ml-auto view-details",attrs:{"href":"https://cms.thinkpro.vn/#/goods/product-options?store_status=2&ttkd=1&limit=50&page=1","target":"_blank"}},[_vm._v("Xem danh sách đặt")])])]),_c('div',[_c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable px-3 py-2",staticStyle:{"overflow":"auto"},attrs:{"headers":_vm.dessertHeaders,"items":_vm.itemsIsArriving,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"show-expand":"","item-key":"stt","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","loading-text":"Đang tải dữ liệu","hide-default-footer":"","calculate-widths":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.product_name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.product_name)+" - "+_vm._s(item.SKU)+" - "+_vm._s(item.short_des))])]}},{key:"item.earliest_est_date",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("formatTime")(item.earliest_est_date))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("formatGetNumberDay")(item.earliest_est_date)))])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"0 !important"},attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable",staticStyle:{"max-height":"300px","overflow":"auto"},attrs:{"headers":_vm.defaultHeaderChild,"items":item.pos,"item-key":"stt","calculate-widths":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","loading-text":"Đang tải dữ liệu","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.minEstDate",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("formatTime")(item.minEstDate))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("formatGetNumberDay")(item.minEstDate)))])])]}}],null,true)})],1)]}}],null,true)}),_c('div',{staticClass:"d-flex align-center justify-end flex-wrap mt-3"},[_c('v-autocomplete',{staticClass:"tp-filter-autocomplete mx-2",staticStyle:{"max-width":"200px"},attrs:{"clearable":"","items":_vm.nguon_hang,"dense":"","deletable-chips":"","multiple":"","hide-details":"","hide-selected":"","item-text":"name","item-value":"id","no-data-text":"Không có dữ liệu","outlined":"","single-line":"","small-chips":"","return-object":"","label":"Outlined","placeholder":"Chọn nguồn hàng"},on:{"change":function($event){return _vm.getArriving()}},model:{value:(_vm.selectFilter.nguon_hang),callback:function ($$v) {_vm.$set(_vm.selectFilter, "nguon_hang", $$v)},expression:"selectFilter.nguon_hang"}}),_c('v-autocomplete',{staticClass:"tp-filter-autocomplete mx-2",staticStyle:{"max-width":"200px"},attrs:{"clearable":"","items":_vm.allProductTypes,"dense":"","deletable-chips":"","multiple":"","hide-details":"","hide-selected":"","item-text":"name","item-value":"id","no-data-text":"Không có dữ liệu","outlined":"","single-line":"","small-chips":"","return-object":"","label":"Outlined","placeholder":"Chọn phụ kiện"},on:{"change":function($event){return _vm.getArriving()}},model:{value:(_vm.selectFilter.category_id),callback:function ($$v) {_vm.$set(_vm.selectFilter, "category_id", $$v)},expression:"selectFilter.category_id"}}),(_vm.itemsIsArriving.length > 0)?_c('v-pagination',{attrs:{"color":"primary","length":_vm.pageCount,"total-visible":5},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }