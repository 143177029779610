<template>
  <div>
    <v-card flat class="pa-4">
      <div class="d-flex flex-row">
        <div>
          <p class="font-weight-bold text-h5 mb-4">
            Khách đặt chờ các anh vào nhập hàng Sản phẩm mới nè, xem ngay để cập
            nhật kiến thức (SKU mới được thêm, hiện web)
          </p>
        </div>
      </div>
      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(item, i) in 3" :key="i">
          <v-expansion-panel-header>
            <v-row>
              <v-col sm="1"
                ><span class="font-weight-bold text-h6">{{ i }}</span></v-col
              >
              <v-col
                ><span class="font-weight-bold"
                  >Tên sản phẩm SKU, MPN</span
                ></v-col
              >
              <v-col><span class="font-weight-bold">SL Đang về</span></v-col>
              <v-col><span class="font-weight-bold">SL Sẵn bán</span></v-col>
              <v-col><span class="font-weight-bold">Còn 5 ngày</span></v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col sm="1"
                ><span class="font-weight-bold text-h6"></span
              ></v-col>
              <v-col><span>Tên sản phẩm SKU, MPN</span></v-col>
              <v-col><span>2</span></v-col>
              <v-col><span>3</span></v-col>
              <v-col><span>5 ngày</span></v-col>
            </v-row>
            <v-row>
              <v-col sm="1"
                ><span class="font-weight-bold text-h6"></span
              ></v-col>
              <v-col><span>Tên sản phẩm SKU, MPN</span></v-col>
              <v-col><span>2</span></v-col>
              <v-col><span>3</span></v-col>
              <v-col><span>5 ngày</span></v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none;
}
.v-expansion-panel-header {
  background: rgb(251 251 251);
}
</style>
